.form-select {
	@include form-text;
	padding: 10px 10px;
	width: 100%;
	max-width: none;
	background-image: url(../img/arrow-select.jpg);
	background-repeat: no-repeat;
	background-position: 100% -2px;
	appearance: none;
    text-indent: 1px;
    text-overflow: '';
	border-radius: 0;
	height: 48px;

	.ff & {
		padding: 13px 10px 12px;
	}

	.ie & {
		background-image: none;
		padding: 10px 10px 9px;
	}

	option {
		font-size: 11px;
	}
}


