.block-webform {
	//@include area-default;
	font-size: 0.9rem;
	padding: 50px 30px;

	@media only screen and (max-width: $mobile) {
		padding-left: 0;
		padding-right: 0;
	}

	@media only screen and (min-width: 1240px) {
		padding: 100px 60px;
	}

	.content {
		position: relative;
	}

	.block-inner {
		border: 2px solid $pastel-marron;
		padding: 45px 30px;
		max-width: 555px;
		width: 100%;
		margin: 0 auto;

		@media only screen and (max-width: $mobile) {
			border: 0;
			padding-left: 20px;
			padding-right: 20px;
		}

		@media only screen and (min-width: 1240px) {
			width: 555px;
		}
	}

	.block__title {
		@include font-heading-teaser-2;
		color: $base-marron;
		margin-bottom: 47px;
		
		@media only screen and (max-width: $mobile) {
			max-width: 80%;
		}
	}

	.webform-component--tipo-de-denuncia {
		width: 100%;
		float: left;
	}

	
	//#webform-component-territorio .form-select,
	.webform-component--territorio,
	.webform-component--nombre-del-denunciado,
	.webform-component--tu-nombre-y-apellidos,
	.webform-component--tu-telefono,
	.webform-component--esta-confirmado {
		float: none;
		width: 100%;

		@media only screen and (min-width: 645px) and (max-width: 1019px) {
			float: left;
			width: 240px;
		}


		@media only screen and (min-width: 1210px) {
			float: left;
			width: 240px;
		}
	}

	.webform-component--tienda-wrapper,
	.webform-component--cargo-del-denunciado,
	.webform-component--tu-dni,
	.webform-component--tu-correo,
	#edit-submitted-adjuntar-archivo-ajax-wrapper,
	#edit-submitted-adjuntar-archivo--2-ajax-wrapper {
		float: none;
		width: 100%;

		@media only screen and (min-width: 645px) and (max-width: 1019px) {
			float: right;
			width: 240px;
		}

		@media only screen and (min-width: 1210px) {
			float: right;
			width: 240px;
		}
	}

	.webform-component--tienda-wrapper {
		border: 0;
		padding: 0;
		margin: 0;

		.fieldset-wrapper {
			.form-item {
				display: none;
			}
		}
	}

	#webform-component-territorio {
		background: url(../img/select-falso.png) no-repeat 100% 0;
		width: 100%;
		float: left;

		.form-select {
			& + .form-select {
				float: right;
				margin-top: 10px;

				@media only screen and (min-width: 645px) and (max-width: 1019px) {
					margin-top: 0;
				}

				@media only screen and (min-width: 1210px) {
					margin-top: 0;
				}
			}
		}
	}
}
