.webform-component-shs {
	select {
		width: 240px;
		float: left;

		@media only screen and (max-width: $mobile) {
			width: 100%;
		}

		& + select {
			float: right;
		}
	}
}
