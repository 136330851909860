.node-type-romunidad-web {
	@include interna-default;
	
	.field-name-title {
		@media only screen and (min-width: $desktop) {
			margin-bottom: 37px;
		}
	}

	// span
	.easy-breadcrumb_segment-title {
		display: none;
	}
	
	.easy-breadcrumb_segment-1 {
		& + .easy-breadcrumb_segment-separator {
			display: none;
		}
	}

	.easy-breadcrumb_segment-2 {
		@include breadcrumb-title;

		& + .easy-breadcrumb_segment-separator {
			display: none;
		}
	}

	.group-left .field-name-field-image {
		> .block {
			height: 100%;

			@media only screen and (max-width: $desktop) {
				background-size: auto 82% !important;
			}
		}
	}
}
