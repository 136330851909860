.page-user {
	background-color: $base-celeste;
	overflow: visible;

	.header {
		position: relative;

		& + .layout-3col {
			padding: 100px 0;
			margin: 0 auto;
			max-width: 600px;
		}
	}
}
