.block {
	&.fade-toggle-modal {
		@include centered-absolute;
		position: fixed;
		background-color: $base-celeste;
		display: none;
	}

	&.half-width {
		
		@media only screen and (min-width: $desktop) {
			float: left;
			width: 50%;
			height: 100%;
		}
	}

	&.area-default {
		.block-inner {
			@include area-default;
		}
	}

	&.area-default-scroll {
		@include area-default-scroll;

		.block-inner {
			padding-top: 110px;

			@media only screen and (min-width: $desktop) {
				overflow-y: scroll;
				width: 100%;
				height: 100%;
			}

			.content {
				height: 100%;
			}
		}
	}

	&.footer-copyright {
		@include copyright;

		@media only screen and (max-width: 1280px) {
			margin-bottom: 20px;
		}
	}

	&.footer-author {
		@include author;
	}
}

