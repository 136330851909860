.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 150;
	display: block;
	padding: 20px 0 0 25px;

	.header__logo {
		@media only screen and (max-width: $tablet) {
			max-width: 150px;

			img {
				min-height: 40px;
			}
		}
	}
}

