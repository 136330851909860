.view {
	&.view-romunidad-web.block-layout.block-cards .views-row .field-name-node-link {
		padding-bottom: 35px;
	}

	&.view.view-romers {
		&.block-layout.block-cards .views-row {
			.group-text-content {
				padding-bottom: 40px;
			}

			.field-name-body {
				color: $dark;
			}
		}
	}
}
