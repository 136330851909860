.field-name-easy-breadcrumb-field {
	display: inline-block !important;
	vertical-align: middle;
	text-align: left;

	.easy-breadcrumb_segment-front {
		&::before {
			content: "> ";
		}
	}

	.easy-breadcrumb_segment-separator {
		color: $dark;
	}

	// a
	.easy-breadcrumb_segment {
		color: $dark;
		text-decoration: none;
	}

	// span
	.easy-breadcrumb_segment-title {
		@include breadcrumb-title;
	}
}
