.not-front {
	// http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/c%C3%B3digo-de-%C3%A9tica-rom
	&.page-node-1 {
		.field #dhtml_menu-553 {
			display: none;
		}
	}

	// http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/actitud-rom
	&.page-node-2 {
		.field #dhtml_menu-547 {
			display: none;
		}
	}	

	// portal-de-etica-rom/nuestros-principios
	&.page-node-3 {
		.field #dhtml_menu-548 {
			display: none;
		}
	}

	// http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/malas-pr%C3%A1cticas
	&.page-node-4 {
		.field #dhtml_menu-549 {
			display: none;
		}
	}

	// http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/registra-una-denuncia
	&.page-node-5 {
		.field #dhtml_menu-550 {
			display: none;
		}
	}

	// http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/la-romunidad-web
	&.node-type-romunidad-web {
		.field #dhtml_menu-551 {
		//	display: none;
		}
	}
}
