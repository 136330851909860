@charset "UTF-8";
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix::before,
.header::before,
.tabs::before {
  content: '';
  display: table;
}

.clearfix::after,
.header::after,
.tabs::after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.jspPane {
  width: 100% !important;
}

.jspDrag {
  background: #bbb;
}

.jspTrack {
  background: #eee;
}

.fc-select-list-wrapper {
  font-size: .9rem;
}

span.selected-arrow {
  background-color: #8b6a50;
  width: 46px;
  height: 46px;
}

span.selected-arrow:hover {
  background-color: #6b513d;
}

span.selected-arrow::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 13px 0 13px;
  border-color: white transparent transparent;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.fc-select-list,
.fc-select-list-selected {
  border: 2px solid #8b6a50;
}

.fc-select-list {
  border-top: 0;
}

h4.fc-select-list-selected {
  height: 48px;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

ul.fc-select-list {
  background: white;
}

ul.fc-select-list li {
  background-color: #8b6a50;
}

ul.fc-select-list li:last-child {
  margin-bottom: 0;
}

ul.fc-select-list li:hover,
ul.fc-select-list li.opt-selected {
  background-color: #6b513d;
  color: white;
}

.selected-text {
  color: #8b6a50;
  margin-top: 16px;
}

.carousel-indicators {
  margin: 0;
}

/*html.front, body.front, 
body.front .layout-center,
body.front .layout-swap,
body.front .layout-3col__full {
	height: 100%;
}*/
body {
  counter-reset: section;
}

@media only screen and (min-width: 1020px) {
  body {
    overflow-y: hidden;
  }
}

body.hamburger-open {
  overflow-y: hidden;
}

@media only screen and (max-width: 480px) {
  body.hamburger-open .header__logo {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  html, body,
  .layout-center,
  .layout-swap,
  .layout-3col__full {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .group-left,
  .group-right {
    height: 100%;
  }
}

html.front, body.front,
.front .layout-center,
.front .layout-swap,
.front .layout-3col__full {
  height: 100%;
}

body {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 14px;
}

@media only screen and (min-width: 1020px) {
  body {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  line-height: 125%;
}

@media only screen and (min-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }
}

p {
  margin: 0 0 15px;
  line-height: 135%;
}

a {
  transition: .3s ease-out all;
}

dl,
menu,
ol,
ul {
  margin: 0 0 15px;
}

.layout-center {
  padding: 0;
  max-width: inherit;
}

.layout-swap,
.layout-3col__full {
  margin: 0;
  padding: 0;
}

.layout-swap__top.layout-3col__full {
  display: none;
}

.region-blockgroup-header-links {
  position: absolute;
  top: 20px;
  right: 25px;
}

.region-blockgroup-header-links::before {
  content: '';
  display: table;
}

.region-blockgroup-header-links::after {
  content: '';
  display: table;
  clear: both;
}

#block-bean-logo-entel,
.hamburger-block {
  float: right;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  #block-bean-logo-entel,
  .hamburger-block {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    position: relative;
    top: 0;
  }
}

.hamburger-button,
.close-button-html {
  width: 40px;
  height: 40px;
  position: absolute;
  overflow: hidden;
  margin: 0 0 0 5px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  border: 1px solid white;
  z-index: 10;
  background-color: #89a1d2;
  display: block !important;
  width: 60px;
  height: 60px;
  border: 0;
  position: relative;
}

@media only screen and (min-width: 480px) {
  .hamburger-button,
  .close-button-html {
    display: none;
  }
}

.hamburger-button span,
.close-button-html span {
  display: block;
  position: absolute;
  width: 25px;
  height: 3.5px;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 13px;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger-button span:nth-child(1),
.close-button-html span:nth-child(1) {
  top: 16px;
}

.hamburger-button span:nth-child(2),
.close-button-html span:nth-child(2) {
  top: 27px;
}

.hamburger-button span:nth-child(3),
.close-button-html span:nth-child(3) {
  top: 38px;
}

@media only screen and (min-width: 480px) and (max-width: 1020px) {
  .hamburger-button span,
  .close-button-html span {
    /*width: 30px;

			&:nth-child(1) {
		  		top: 14px;
			}

			&:nth-child(2) {
		  		top: 24px;
			}

			&:nth-child(3) {
		  		top: 34px;
			}*/
  }
}

@media only screen and (max-width: 768px) {
  .hamburger-button span,
  .close-button-html span {
    width: 26px !important;
    left: 7px !important;
    height: 4px !important;
  }
  .hamburger-button span:nth-child(1),
  .close-button-html span:nth-child(1) {
    top: 8px;
  }
  .hamburger-button span:nth-child(2),
  .close-button-html span:nth-child(2) {
    top: 18px;
  }
  .hamburger-button span:nth-child(3),
  .close-button-html span:nth-child(3) {
    top: 28px;
  }
}

.hamburger-button.open span:nth-child(1),
.close-button-html.open span:nth-child(1) {
  top: 28px;
  transform: rotate(135deg);
}

@media only screen and (max-width: 768px) {
  .hamburger-button.open span:nth-child(1),
  .close-button-html.open span:nth-child(1) {
    top: 19px;
  }
}

.hamburger-button.open span:nth-child(2),
.close-button-html.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger-button.open span:nth-child(3),
.close-button-html.open span:nth-child(3) {
  top: 28px;
  transform: rotate(-135deg);
}

@media only screen and (max-width: 768px) {
  .hamburger-button.open span:nth-child(3),
  .close-button-html.open span:nth-child(3) {
    top: 19px;
  }
}

@media only screen and (max-width: 768px) {
  .hamburger-button,
  .close-button-html {
    width: 40px;
    height: 40px;
  }
}

.hamburger-button span,
.close-button-html span {
  background-color: white;
  width: 34px;
  height: 6px;
  border-radius: 0;
}

div .close-button-html {
  width: 49px;
  height: 51px;
}

div .close-button-html span {
  left: 8px;
  height: 6px !important;
  width: 34px !important;
}

div .close-button-html.open span:nth-child(1),
div .close-button-html.open span:nth-child(3) {
  top: 23px !important;
}

.region-blockgroup-section-2,
.region-blockgroup-blockgroup-inner {
  height: 100%;
}

.region-blockgroup-blockgroup-inner {
  height: 50%;
  width: 100%;
  padding: 0 20px;
  min-height: 450px;
  position: relative;
  /*
	height: 50%;
	min-height: 450px;
	position: relative;
	text-align: center;

	@media only screen and (min-width: $desktop) {
		float: left;
		width: 50%;
		height: 100%;
	}*/
}

@media only screen and (min-width: 1020px) {
  .region-blockgroup-blockgroup-inner {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1020px) {
  .region-blockgroup-blockgroup-inner {
    background-size: auto 62% !important;
  }
}

@media only screen and (max-width: 1020px) {
  .region-blockgroup-blockgroup-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.region-blockgroup-blockgroup-inner .block-portada {
  width: 100%;
}

.region-blockgroup-blockgroup-inner .field-name-easy-breadcrumb-field {
  display: table;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  margin: auto;
  text-align: center;
  z-index: 50;
  padding-left: 20px;
  padding-right: 20px;
}

.region-blockgroup-blockgroup-inner .field-name-easy-breadcrumb-field > * {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (min-width: 1020px) {
  .region-blockgroup-blockgroup-inner .field-name-easy-breadcrumb-field {
    width: 500px;
    text-align: left;
  }
}

@media only screen and (max-width: 1020px) {
  .region-blockgroup-blockgroup-inner .field-name-easy-breadcrumb-field > * {
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
}

.region-blockgroup-blockgroup-inner .field-name-easy-breadcrumb-field .easy-breadcrumb_segment-front + .easy-breadcrumb_segment-separator {
  display: none;
}

.close-button-html.open {
  display: block;
  z-index: 10;
  position: absolute;
  top: -12px;
  right: 1px;
  background-color: #8b6a50;
}

.close-button-html.open:hover {
  background-color: #6b513d;
}

.region-footer {
  border-top: 1px solid #ccc;
  overflow: hidden;
  padding: 28px 35px 35px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.region-footer div.block .block-inner {
  height: auto;
  padding: 0;
  overflow: auto;
}

.messages {
  padding-top: 1em;
  padding-bottom: 1em;
}

.messages p {
  margin: 0;
}

.tabs {
  padding: 0 30px;
  margin-bottom: 20px;
}

textarea,
input[type="text"],
input[type="submit"],
input[type="password"],
input[type="email"],
select {
  appearance: none;
  border-radius: 0;
}

.necessary {
  box-shadow: 0.5px 0.5px 2px red, -0.5px -0.5px 2px red;
}

.layout-3col + .footer {
  display: none;
}

.form-item {
  margin: 0 0 10px;
}

.form-item::before {
  content: '';
  display: table;
}

.form-item::after {
  content: '';
  display: table;
  clear: both;
}

.form-text {
  border: 2px solid #8b6a50;
  font-size: inherit;
  padding: 10px;
  width: 100%;
  height: 48px;
  color: #8b6a50;
}

.form-text::placeholder {
  opacity: 1;
  color: #8b6a50;
}

.form-textarea {
  border: 2px solid #8b6a50;
  font-size: inherit;
  padding: 10px;
  width: 100%;
  height: 48px;
  color: #8b6a50;
  resize: vertical;
  min-height: 105px;
  height: 105px;
  display: block;
}

.form-textarea::placeholder {
  opacity: 1;
  color: #8b6a50;
}

form .webform-component-file .description {
  display: none;
}

form .webform-component-file .form-managed-file {
  position: relative;
  z-index: 10;
  /*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file
				
				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
}

form .webform-component-file label {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #d9e4fb;
  color: #4d2200;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
  margin: 0;
  background-color: #fff;
  background-size: 15px auto;
  color: #8b6a50;
  border: 2px solid #8b6a50;
  transition: .3s ease-out all;
  width: 100%;
  padding-top: .63rem;
  padding-bottom: .63rem;
  padding: 11px 10px 9px !important;
}

@media only screen and (min-width: 1020px) {
  form .webform-component-file label {
    padding: 0.8em 0.9em;
  }
}

form .webform-component-file label:hover {
  background-color: #01549f;
  color: #d9e4fb;
}

form .webform-component-file label:hover {
  background-color: #6b513d;
  border-color: #6b513d;
  color: white;
}

form .webform-component-file .form-file {
  height: 50.1px;
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

form .webform-component-file .form-submit {
  display: none;
}

.form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
  border: 0;
  color: white;
  background-color: #8b6a50;
  display: block;
  text-align: center;
  font-size: inherit;
  width: 100%;
  transition: .3s ease-out all;
  padding: 0.8em 0.9em;
}

@media only screen and (min-width: 1020px) {
  .form-submit {
    padding: 0.8em 0.9em;
  }
}

.form-submit:hover {
  background-color: #01549f;
}

.form-submit:hover {
  background-color: #6b513d;
  color: white;
}

.form-select {
  border: 2px solid #8b6a50;
  font-size: inherit;
  padding: 10px;
  width: 100%;
  height: 48px;
  color: #8b6a50;
  padding: 10px 10px;
  width: 100%;
  max-width: none;
  background-image: url(../img/arrow-select.jpg);
  background-repeat: no-repeat;
  background-position: 100% -2px;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border-radius: 0;
  height: 48px;
}

.form-select::placeholder {
  opacity: 1;
  color: #8b6a50;
}

.ff .form-select {
  padding: 13px 10px 12px;
}

.ie .form-select {
  background-image: none;
  padding: 10px 10px 9px;
}

.form-select option {
  font-size: 11px;
}

.webform-component-shs select {
  width: 240px;
  float: left;
}

@media only screen and (max-width: 480px) {
  .webform-component-shs select {
    width: 100%;
  }
}

.webform-component-shs select + select {
  float: right;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 150;
  display: block;
  padding: 20px 0 0 25px;
}

@media only screen and (max-width: 768px) {
  .header .header__logo {
    max-width: 150px;
  }
  .header .header__logo img {
    min-height: 40px;
  }
}

.block-portada {
  /*div.contextual-links-wrapper ul.contextual-links {
		
	}*/
  height: 50%;
  width: 100%;
  padding: 0 20px;
  min-height: 450px;
  position: relative;
  text-align: center;
}

.block-portada div.contextual-links-wrapper {
  top: 70px;
}

@media only screen and (min-width: 1020px) {
  .block-portada {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1020px) {
  .block-portada {
    background-size: auto 62% !important;
  }
}

.block-portada .block__title {
  font-size: 2em;
  line-height: 110%;
  font-weight: 700;
  color: #4d2200;
}

.block-portada .block__title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media only screen and (min-width: 1020px) {
  .block-portada .block__title {
    font-size: 2.5em;
    line-height: 110%;
  }
}

.block-portada .field-name-field-body {
  max-width: 520px;
  margin: 0 auto 17px;
}

.block-portada .field-name-field-body p {
  margin: 0;
}

@media only screen and (min-width: 1020px) {
  .block-portada .field-name-field-body {
    margin-bottom: 23px;
  }
}

.block-portada .field-name-field-link {
  margin-bottom: 10px;
}

@media only screen and (min-width: 1020px) {
  .block-portada .field-name-field-link {
    margin-bottom: 20px;
  }
}

.block-portada .field-name-field-link a,
.block-portada .field-name-field-links a {
  width: 240px;
}

.block-portada .field-name-field-links {
  width: 100%;
  position: absolute;
  top: auto;
  left: 0;
}

.block-portada .field-name-field-links .field-item {
  margin-bottom: 10px;
}

.block-portada .field-name-field-links a {
  text-align: left;
}

@media only screen and (min-width: 1020px) {
  .block-portada .field-name-field-links {
    top: 105%;
    top: calc(100% + 25px);
  }
  .block-portada .field-name-field-links .field-item {
    margin-bottom: 20px;
  }
  .block-portada .field-name-field-links a {
    text-align: center;
  }
}

.block-portada.section-portal-etica {
  background-color: #d9e4fb;
}

.block-portada.primary {
  background-color: #d9e4fb;
}

.block-portada.primary .block__title,
.block-portada.primary .field-name-field-body p {
  color: #4d2200;
}

.block-portada.primary .field-name-field-link a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
}

@media only screen and (min-width: 1020px) {
  .block-portada.primary .field-name-field-link a {
    padding: 0.8em 0.9em;
  }
}

.block-portada.primary .field-name-field-link a:hover {
  background-color: #01549f;
}

.block-portada.primary .field-name-field-links a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #89a1d2;
  color: #d9e4fb;
  background-position: 17px 50%;
  background-repeat: no-repeat;
  padding-left: 2.7rem;
}

@media only screen and (min-width: 1020px) {
  .block-portada.primary .field-name-field-links a {
    padding: 0.8em 0.9em;
  }
}

.block-portada.primary .field-name-field-links a:hover {
  background-color: #01549f;
}

.block-portada.primary .field-name-field-links .field-item:nth-of-type(1) a {
  background-image: url(../img/icon-person.png);
}

.block-portada.primary .field-name-field-links .field-item:nth-of-type(2) a {
  background-image: url(../img/icon-arrow.png);
}

.block-portada.secondary {
  background-color: #4d2200;
}

.block-portada.secondary .block__title,
.block-portada.secondary .field-name-field-body {
  color: #d9e4fb;
}

.block-portada.secondary .field-name-field-link a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #d9e4fb;
  color: #4d2200;
}

@media only screen and (min-width: 1020px) {
  .block-portada.secondary .field-name-field-link a {
    padding: 0.8em 0.9em;
  }
}

.block-portada.secondary .field-name-field-link a:hover {
  background-color: #01549f;
  color: #d9e4fb;
}

.block-portada.secondary .block__title {
  font-weight: 500;
}

.block-portada .block-inner {
  display: table;
  height: 100%;
  width: 100%;
}

.block-portada .block-inner > * {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (min-width: 1020px) {
  .block-portada .content-inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.block-portada .group-text-content {
  position: relative;
  z-index: 20;
}

.block-portada .field-name-field-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 15;
}

@media only screen and (max-width: 1020px) {
  .block-portada .field-name-field-image {
    padding: 20px;
  }
}

.block-portada img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 450px;
  display: block;
  margin: 0 auto;
}

@media only screen and (min-width: 1020px) {
  .block-portada img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 82%;
    max-height: inherit;
  }
}

.block.fade-toggle-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  background-color: #d9e4fb;
  display: none;
}

@media only screen and (min-width: 1020px) {
  .block.half-width {
    float: left;
    width: 50%;
    height: 100%;
  }
}

.block.area-default .block-inner {
  padding: 50px 60px;
}

@media only screen and (min-width: 768px) {
  .block.area-default .block-inner {
    padding: 100px 60px;
  }
}

.block.area-default-scroll {
  /*padding-top: 110px;
	
	& > * {
		padding: 0 51px;
	}
	*/
}

.block.area-default-scroll .block-inner {
  padding-top: 110px;
}

@media only screen and (min-width: 1020px) {
  .block.area-default-scroll .block-inner {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }
}

.block.area-default-scroll .block-inner .content {
  height: 100%;
}

.block.footer-copyright {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .block.footer-copyright {
    float: left;
    text-align: right;
  }
}

.block.footer-copyright p {
  font-size: .9em;
  color: #83858c;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .block.footer-copyright p {
    float: left;
  }
}

@media only screen and (max-width: 1280px) {
  .block.footer-copyright {
    margin-bottom: 20px;
  }
}

.block.footer-author {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .block.footer-author {
    float: right;
    text-align: right;
  }
}

.block.footer-author .block-inner {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

.block.footer-author .block__title {
  font-size: .7em;
  float: left;
  margin: 5px 5px 0 0;
  color: #83858c;
}

.block.footer-author .entity {
  float: left;
}

.block.footer-author .field-name-field-image {
  margin: 0 !important;
}

.block.footer-author img {
  max-width: 65px;
  height: auto;
}

.main-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*div.contextual-links-wrapper ul.contextual-links {
		
	}*/
  z-index: 100;
}

.main-menu div.contextual-links-wrapper {
  top: 100px;
}

.main-menu .block-inner {
  margin: auto 20px;
  height: 100%;
  margin: auto 10%;
}

@media only screen and (min-width: 1020px) {
  .main-menu .block-inner {
    margin: auto 40%;
  }
}

.main-menu .block-inner .content {
  display: table;
  height: 100%;
  width: 100%;
}

.main-menu .block-inner .content > * {
  display: table-cell;
  vertical-align: middle;
}

.main-menu .content-inner {
  text-align: center;
  position: relative;
}

.main-menu .block__title {
  font-size: 2em;
  line-height: 110%;
  font-weight: 700;
  text-align: center;
  color: #4d2200;
  margin-bottom: 10px;
}

.main-menu .block__title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media only screen and (min-width: 1020px) {
  .main-menu .block__title {
    font-size: 2.5em;
    line-height: 110%;
  }
}

@media only screen and (max-width: 480px) {
  .main-menu .block__title {
    display: none;
  }
}

.main-menu .menu {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding: 0;
  margin: 0;
}

.main-menu .menu .menu__item {
  list-style: none;
  margin-bottom: 12px;
}

.main-menu .menu .menu__item.last {
  margin-bottom: 0;
}

.main-menu .menu .menu__link {
  color: #4d2200;
  text-decoration: none;
}

.main-menu .menu .menu__link:hover, .main-menu .menu .menu__link.is-active-trail {
  color: #01549f;
}

.main-menu .menu .menu__link::before {
  content: "> ";
}

.main-menu .menu .menu__link a {
  color: #4d2200;
  text-decoration: none;
}

.main-menu .menu .menu__link a.active {
  color: #01549f;
}

.block-layout.block-cards > .block-inner > .content > .content-inner > div.contextual-links-wrapper {
  top: 120px;
}

.block-layout.block-cards .view {
  width: 100%;
  overflow: hidden;
}

.block-layout.block-cards .view-content {
  text-align: center;
  padding-bottom: 60px;
}

.block-layout.block-cards .views-row {
  display: inline-block;
  vertical-align: top;
  width: 280px;
  margin-bottom: 40px;
  box-sizing: content-box;
}

@media only screen and (min-width: 480px) {
  .block-layout.block-cards .views-row {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.block-layout.block-cards .views-row > * {
  border: 1px solid #ccc;
  color: #5c6368;
}

.block-layout.block-cards .views-row .group-text-content {
  padding-bottom: 20px;
}

.block-layout.block-cards .views-row .field-name-title {
  font-size: 1.2em;
  line-height: 110%;
  text-align: center;
  color: #5c6368;
  padding: 0 20px;
  margin-bottom: 13px;
}

.block-layout.block-cards .views-row .field-name-title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.block-layout.block-cards .views-row .field-name-title * {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

.block-layout.block-cards .views-row .field-name-body {
  font-size: 0.85em;
  color: #3d464d;
  padding: 0 20px;
  text-align: left;
  line-height: 140%;
}

.block-layout.block-cards .views-row .field-name-body * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  margin: 0;
  line-height: inherit;
}

.block-layout.block-cards .views-row .field-name-field-image, .block-layout.block-cards .views-row .field-type-video-embed-field {
  margin-bottom: 22px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.block-layout.block-cards .views-row .field-name-field-image a, .block-layout.block-cards .views-row .field-type-video-embed-field a {
  display: block;
}

.block-layout.block-cards .views-row .field-name-field-image img, .block-layout.block-cards .views-row .field-type-video-embed-field img {
  display: block;
  margin: auto;
  height: 200px;
  width: auto;
}

.block-layout.block-cards .views-row .field-name-field-video-embed img {
  width: 100%;
}

.block-layout.block-cards .views-row .field-name-node-link {
  padding: 0 20px 20px;
}

.block-layout.block-cards .views-row .field-name-node-link a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  border: 2px solid #89a1d2;
  color: #89a1d2;
  color: #5c6368;
  border: 1px solid #ccc;
  display: block;
  text-align: center;
}

@media only screen and (min-width: 1020px) {
  .block-layout.block-cards .views-row .field-name-node-link a {
    padding: 0.8em 0.9em;
  }
}

.block-layout.block-cards .views-row .field-name-node-link a:hover {
  border-color: #6484c4;
  color: #6484c4;
}

.block-layout.block-cards .views-row .group-image-wrapper {
  position: relative;
}

.block-layout.block-cards .views-row .field-name-field-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.block-layout.block-cards .views-row .field-name-field-color .field-items,
.block-layout.block-cards .views-row .field-name-field-color .field-item {
  height: 100%;
  width: 100%;
}

.block-layout.block-cards .views-row .field-name-field-color .colorfield-color-swatch {
  width: 100% !important;
  height: 100% !important;
}

.block-layout.block-cards .views-row .field-name-field-cargo {
  font-size: .9em;
  line-height: 100%;
  margin-bottom: 3px;
}

.block-layout.block-cards .views-row .field-name-field-sucursal {
  margin-top: -13px;
  margin-bottom: 3px;
  font-size: .9em;
  line-height: 100%;
}

.block-layout.block-cards .views-row .field-name-field-fecha {
  margin-bottom: 15px;
  font-size: .9em;
  line-height: 100%;
}

.field-name-easy-breadcrumb-field {
  display: inline-block !important;
  vertical-align: middle;
  text-align: left;
}

.field-name-easy-breadcrumb-field .easy-breadcrumb_segment-front::before {
  content: "> ";
}

.field-name-easy-breadcrumb-field .easy-breadcrumb_segment-separator {
  color: #5c6368;
}

.field-name-easy-breadcrumb-field .easy-breadcrumb_segment {
  color: #5c6368;
  text-decoration: none;
}

.field-name-easy-breadcrumb-field .easy-breadcrumb_segment-title {
  font-size: 2em;
  line-height: 110%;
  font-weight: 700;
  display: block;
  color: #4d2200;
  font-weight: 600;
}

.field-name-easy-breadcrumb-field .easy-breadcrumb_segment-title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media only screen and (min-width: 1020px) {
  .field-name-easy-breadcrumb-field .easy-breadcrumb_segment-title {
    font-size: 2.5em;
    line-height: 110%;
  }
}

.block-webform {
  font-size: 0.9rem;
  padding: 50px 30px;
}

@media only screen and (max-width: 480px) {
  .block-webform {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1240px) {
  .block-webform {
    padding: 100px 60px;
  }
}

.block-webform .content {
  position: relative;
}

.block-webform .block-inner {
  border: 2px solid #8b6a50;
  padding: 45px 30px;
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 480px) {
  .block-webform .block-inner {
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 1240px) {
  .block-webform .block-inner {
    width: 555px;
  }
}

.block-webform .block__title {
  font-size: 1.3em;
  line-height: 110%;
  font-weight: 600;
  color: #4d2200;
  margin-bottom: 47px;
}

.block-webform .block__title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .block-webform .block__title {
    max-width: 80%;
  }
}

.block-webform .webform-component--tipo-de-denuncia {
  width: 100%;
  float: left;
}

.block-webform .webform-component--territorio,
.block-webform .webform-component--nombre-del-denunciado,
.block-webform .webform-component--tu-nombre-y-apellidos,
.block-webform .webform-component--tu-telefono,
.block-webform .webform-component--esta-confirmado {
  float: none;
  width: 100%;
}

@media only screen and (min-width: 645px) and (max-width: 1019px) {
  .block-webform .webform-component--territorio,
  .block-webform .webform-component--nombre-del-denunciado,
  .block-webform .webform-component--tu-nombre-y-apellidos,
  .block-webform .webform-component--tu-telefono,
  .block-webform .webform-component--esta-confirmado {
    float: left;
    width: 240px;
  }
}

@media only screen and (min-width: 1210px) {
  .block-webform .webform-component--territorio,
  .block-webform .webform-component--nombre-del-denunciado,
  .block-webform .webform-component--tu-nombre-y-apellidos,
  .block-webform .webform-component--tu-telefono,
  .block-webform .webform-component--esta-confirmado {
    float: left;
    width: 240px;
  }
}

.block-webform .webform-component--tienda-wrapper,
.block-webform .webform-component--cargo-del-denunciado,
.block-webform .webform-component--tu-dni,
.block-webform .webform-component--tu-correo,
.block-webform #edit-submitted-adjuntar-archivo-ajax-wrapper,
.block-webform #edit-submitted-adjuntar-archivo--2-ajax-wrapper {
  float: none;
  width: 100%;
}

@media only screen and (min-width: 645px) and (max-width: 1019px) {
  .block-webform .webform-component--tienda-wrapper,
  .block-webform .webform-component--cargo-del-denunciado,
  .block-webform .webform-component--tu-dni,
  .block-webform .webform-component--tu-correo,
  .block-webform #edit-submitted-adjuntar-archivo-ajax-wrapper,
  .block-webform #edit-submitted-adjuntar-archivo--2-ajax-wrapper {
    float: right;
    width: 240px;
  }
}

@media only screen and (min-width: 1210px) {
  .block-webform .webform-component--tienda-wrapper,
  .block-webform .webform-component--cargo-del-denunciado,
  .block-webform .webform-component--tu-dni,
  .block-webform .webform-component--tu-correo,
  .block-webform #edit-submitted-adjuntar-archivo-ajax-wrapper,
  .block-webform #edit-submitted-adjuntar-archivo--2-ajax-wrapper {
    float: right;
    width: 240px;
  }
}

.block-webform .webform-component--tienda-wrapper {
  border: 0;
  padding: 0;
  margin: 0;
}

.block-webform .webform-component--tienda-wrapper .fieldset-wrapper .form-item {
  display: none;
}

.block-webform #webform-component-territorio {
  background: url(../img/select-falso.png) no-repeat 100% 0;
  width: 100%;
  float: left;
}

.block-webform #webform-component-territorio .form-select + .form-select {
  float: right;
  margin-top: 10px;
}

@media only screen and (min-width: 645px) and (max-width: 1019px) {
  .block-webform #webform-component-territorio .form-select + .form-select {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1210px) {
  .block-webform #webform-component-territorio .form-select + .form-select {
    margin-top: 0;
  }
}

.view.view-romunidad-web.block-layout.block-cards .views-row .field-name-node-link {
  padding-bottom: 35px;
}

.view.view.view-romers.block-layout.block-cards .views-row .group-text-content {
  padding-bottom: 40px;
}

.view.view.view-romers.block-layout.block-cards .views-row .field-name-body {
  color: #5c6368;
}

.front .blockgroup {
  height: 100%;
}

.not-front.page-node-1 .field #dhtml_menu-553 {
  display: none;
}

.not-front.page-node-2 .field #dhtml_menu-547 {
  display: none;
}

.not-front.page-node-3 .field #dhtml_menu-548 {
  display: none;
}

.not-front.page-node-4 .field #dhtml_menu-549 {
  display: none;
}

.not-front.page-node-5 .field #dhtml_menu-550 {
  display: none;
}

.node-type-portal-de-etica {
  /* NODE TEXT STYLES v1 */
}

.node-type-portal-de-etica .view-mode-full .field-name-body-summary p {
  font-size: 1.1em;
  line-height: 155%;
}

.node-type-portal-de-etica .view-mode-full .field-name-body {
  /*li,
			blockquote,
			table td {
				font-size: 19px; line-height: 155%
			}*/
}

.node-type-portal-de-etica .view-mode-full .field-name-body p {
  font-size: 1em;
  color: #5c6368;
  line-height: 165%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body p {
    margin-bottom: 20px;
    font-size: 1.1em;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body p a {
  color: #c37b00;
  font-weight: bold;
}

.node-type-portal-de-etica .view-mode-full .field-name-body p a:hover {
  color: #c37b00;
}

.node-type-portal-de-etica .view-mode-full .field-name-body p strong {
  color: #c37b00;
  font-weight: bold;
}

.node-type-portal-de-etica .view-mode-full .field-name-body blockquote {
  font-family: 700;
  font-weight: normal;
  color: #5c6368;
  background-color: #ddd;
  margin: 0 0 20px;
  padding: 30px 35px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body blockquote {
    margin: 0 1em 20px;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body blockquote p {
  margin-bottom: 0;
}

.node-type-portal-de-etica .view-mode-full .field-name-body blockquote p + p {
  margin-top: 10px;
}

.node-type-portal-de-etica .view-mode-full .field-name-body blockquote a {
  color: #8e2321;
}

.node-type-portal-de-etica .view-mode-full .field-name-body blockquote strong {
  color: #8e2321;
}

.node-type-portal-de-etica .view-mode-full .field-name-body ul, .node-type-portal-de-etica .view-mode-full .field-name-body ol {
  padding-left: 16px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body ul, .node-type-portal-de-etica .view-mode-full .field-name-body ol {
    padding-left: 16px;
    margin-bottom: 20px;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body li {
  font-size: 1em;
  line-height: 165%;
  padding-left: 6.66667px;
  margin-bottom: 20px;
  color: #5c6368;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body li {
    padding-left: 20px;
    font-size: 1.1em;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body li strong {
  color: #4d2200;
  font-weight: 700;
}

.node-type-portal-de-etica .view-mode-full .field-name-body li a {
  color: #5c6368;
}

.node-type-portal-de-etica .view-mode-full .field-name-body td {
  font-size: 1.1em;
  line-height: 160%;
}

.node-type-portal-de-etica .view-mode-full .field-name-body p + h1, .node-type-portal-de-etica .view-mode-full .field-name-body p + h2, .node-type-portal-de-etica .view-mode-full .field-name-body p + h3, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h1, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h2, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h3, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h1, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h2, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h3,
.node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h1, .node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h2, .node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h3,
.node-type-portal-de-etica .view-mode-full .field-name-body table + h1, .node-type-portal-de-etica .view-mode-full .field-name-body table + h2, .node-type-portal-de-etica .view-mode-full .field-name-body table + h3 {
  margin-top: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body p + h1, .node-type-portal-de-etica .view-mode-full .field-name-body p + h2, .node-type-portal-de-etica .view-mode-full .field-name-body p + h3, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h1, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h2, .node-type-portal-de-etica .view-mode-full .field-name-body ul + h3, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h1, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h2, .node-type-portal-de-etica .view-mode-full .field-name-body ol + h3,
  .node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h1, .node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h2, .node-type-portal-de-etica .view-mode-full .field-name-body blockquote + h3,
  .node-type-portal-de-etica .view-mode-full .field-name-body table + h1, .node-type-portal-de-etica .view-mode-full .field-name-body table + h2, .node-type-portal-de-etica .view-mode-full .field-name-body table + h3 {
    margin-top: 20px;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body img {
  max-width: 100%;
  height: auto;
}

.node-type-portal-de-etica .view-mode-full .field-name-body img[style*="float: left;"] {
  margin-right: 20px;
}

.node-type-portal-de-etica .view-mode-full .field-name-body img[style*="float: right;"] {
  margin-left: 20px;
}

.node-type-portal-de-etica .view-mode-full .field-name-body .field {
  margin-bottom: 20px;
}

.node-type-portal-de-etica .view-mode-full .field-name-body ul li {
  position: relative;
  list-style: none;
}

.node-type-portal-de-etica .view-mode-full .field-name-body ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background: #4d2200;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 13px;
}

@media only screen and (max-width: 1020px) {
  .node-type-portal-de-etica .view-mode-full .field-name-body ul li::before {
    top: 9px;
  }
}

.node-type-portal-de-etica .view-mode-full .field-name-body ol li {
  list-style: none;
  position: relative;
}

.node-type-portal-de-etica .view-mode-full .field-name-body ol li::before {
  position: absolute;
  left: -15px;
  top: 0;
  color: #4d2200;
  counter-increment: section;
  content: counter(section, decimal) ") ";
}

.node-type-portal-de-etica .view-mode-full .field-name-body ol + ul,
.node-type-portal-de-etica .view-mode-full .field-name-body ul + ol {
  margin-left: 35px;
}

.node-type-portal-de-etica .node {
  height: 100%;
}

.node-type-portal-de-etica .group-left {
  background-color: #d9e4fb;
}

.node-type-portal-de-etica .group-left .field-name-field-image {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.node-type-portal-de-etica .group-left .field-name-field-image .field-items,
.node-type-portal-de-etica .group-left .field-name-field-image .field-item {
  width: 100%;
  height: 100%;
}

.node-type-portal-de-etica .group-left .field-name-field-image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

.node-type-portal-de-etica #block-bean-romunidad-foto-fija-1,
.node-type-portal-de-etica .group-left,
.node-type-portal-de-etica .group-right {
  height: 50%;
  width: 100%;
  padding: 0 20px;
  min-height: 450px;
  position: relative;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica #block-bean-romunidad-foto-fija-1,
  .node-type-portal-de-etica .group-left,
  .node-type-portal-de-etica .group-right {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1020px) {
  .node-type-portal-de-etica #block-bean-romunidad-foto-fija-1,
  .node-type-portal-de-etica .group-left,
  .node-type-portal-de-etica .group-right {
    background-size: auto 62% !important;
  }
}

.node-type-portal-de-etica #block-bean-romunidad-foto-fija-1 {
  width: 100%;
}

.node-type-portal-de-etica .group-right {
  /*padding-top: 110px;
	
	& > * {
		padding: 0 51px;
	}
	*/
}

@media only screen and (max-width: 1020px) {
  .node-type-portal-de-etica .group-right {
    height: auto;
    padding: 0;
  }
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .group-right > .group-text-content {
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 40px;
  }
}

.node-type-portal-de-etica .group-right .field-name-field-video-embed {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .group-right .field-name-field-video-embed {
    margin-bottom: 32px;
  }
}

.node-type-portal-de-etica .group-right .field-name-field-video-embed iframe {
  max-width: 100%;
  display: block;
}

.node-type-portal-de-etica .group-right .field-name-field-image {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .group-right .field-name-field-image {
    margin-bottom: 32px;
  }
}

.node-type-portal-de-etica .group-right .field-name-field-image img {
  width: 100%;
  height: auto;
  display: block;
}

.node-type-portal-de-etica .field-name-title {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.node-type-portal-de-etica .field-name-title * {
  margin: 0;
}

.node-type-portal-de-etica .group-title-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 20;
}

.node-type-portal-de-etica .group-title-content .field-group-div {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.node-type-portal-de-etica .group-title-content .field-group-div > * {
  display: table-cell;
  vertical-align: middle;
}

.node-type-portal-de-etica main .field-name-title {
  font-size: 1.3em;
  line-height: 110%;
  font-weight: 600;
  color: #4d2200;
}

.node-type-portal-de-etica main .field-name-title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.node-type-portal-de-etica .field-name-main-menu {
  padding-bottom: 30px;
  margin-top: 30px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .field-name-main-menu {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}

.node-type-portal-de-etica .field-name-main-menu .front-page,
.node-type-portal-de-etica .field-name-main-menu .portal-etica {
  display: none;
}

.node-type-portal-de-etica .field-name-main-menu .block__title {
  display: none;
}

.node-type-portal-de-etica .field-name-main-menu .visitado .menu__item a {
  background-color: #d9e4fb;
}

.node-type-portal-de-etica .field-name-main-menu .views-row-inner {
  position: relative;
}

.node-type-portal-de-etica .field-name-main-menu .views-row-inner:hover .menu__item + .colorfield-color-swatch {
  opacity: 0;
}

.node-type-portal-de-etica .field-name-main-menu .views-row-inner:hover .default-a {
  opacity: 0;
}

.node-type-portal-de-etica .field-name-main-menu .menu {
  padding: 0;
  margin: 0;
}

.node-type-portal-de-etica .field-name-main-menu .menu__item {
  list-style: none;
  margin-bottom: 24px;
  z-index: 50;
  position: relative;
}

.node-type-portal-de-etica .field-name-main-menu .menu__item a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
  color: #3d464d;
  padding: 1.5em 1em;
  background: none;
  padding: 1.5em 0 !important;
  display: block;
  text-align: center;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .field-name-main-menu .menu__item a {
    padding: 0.8em 0.9em;
  }
}

.node-type-portal-de-etica .field-name-main-menu .menu__item a:hover {
  background-color: #01549f;
}

.node-type-portal-de-etica .field-name-main-menu .menu__item a:hover {
  color: #3d464d;
  background-color: #d9e4fb;
}

.node-type-portal-de-etica .field-name-main-menu .colorfield-color-swatch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 30;
  transition: .3s ease-out all;
}

.node-type-portal-de-etica .field-name-main-menu .colorfield-color-swatch + .default-color {
  display: none;
}

.node-type-portal-de-etica .field-name-main-menu .default-color .default-a,
.node-type-portal-de-etica .field-name-main-menu .default-color .default-b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 30;
  transition: .3s ease-out all;
  background: #89a1d2;
}

.node-type-portal-de-etica .field-name-main-menu .default-color .default-b {
  z-index: 20;
  background: #01549f;
}

.node-type-portal-de-etica .group-slide-content {
  max-width: 730px;
  margin: 0 auto;
  padding: 50px 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .group-slide-content {
    padding-top: 110px;
    padding: 110px 40px 50px;
    min-height: 100%;
  }
}

.node-type-portal-de-etica .field-name-field-links {
  margin-top: 60px;
}

.node-type-portal-de-etica .field-name-field-links .field-item {
  margin-bottom: 22px;
}

.node-type-portal-de-etica .field-name-field-links a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
  color: #3d464d;
  padding: 1.5em 1em;
  background-color: #8b6a50;
  display: block;
  text-align: center;
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .field-name-field-links a {
    padding: 0.8em 0.9em;
  }
}

.node-type-portal-de-etica .field-name-field-links a:hover {
  background-color: #01549f;
}

.node-type-portal-de-etica .field-name-field-links a:hover {
  background-color: #6b513d;
  color: white;
}

@media only screen and (min-width: 1020px) {
  .node-type-portal-de-etica .group-conditional-blocks {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.node-type-portal-de-etica .group-footer {
  border-top: 1px solid #ccc;
  overflow: hidden;
  padding: 28px 35px 35px;
}

.node-type-portal-de-etica .field-name-footer-copyright {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-portal-de-etica .field-name-footer-copyright {
    float: left;
    text-align: right;
  }
}

.node-type-portal-de-etica .field-name-footer-copyright p {
  font-size: .9em;
  color: #83858c;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-portal-de-etica .field-name-footer-copyright p {
    float: left;
  }
}

.node-type-portal-de-etica .field-name-footer-author {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-portal-de-etica .field-name-footer-author {
    float: right;
    text-align: right;
  }
}

.node-type-portal-de-etica .field-name-footer-author .block-inner {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

.node-type-portal-de-etica .field-name-footer-author .block__title {
  font-size: .7em;
  float: left;
  margin: 5px 5px 0 0;
  color: #83858c;
}

.node-type-portal-de-etica .field-name-footer-author .entity {
  float: left;
}

.node-type-portal-de-etica .field-name-footer-author .field-name-field-image {
  margin: 0 !important;
}

.node-type-portal-de-etica .field-name-footer-author img {
  max-width: 65px;
  height: auto;
}

.node-type-portal-de-etica .easy-breadcrumb_segment-1 + .easy-breadcrumb_segment-separator {
  display: none;
}

.page-portal-de-etica-rom .view,
.page-portal-de-etica-rom .view-header {
  height: 100%;
}

.node-type-romunidad-web {
  /* NODE TEXT STYLES v1 */
}

.node-type-romunidad-web .view-mode-full .field-name-body-summary p {
  font-size: 1.1em;
  line-height: 155%;
}

.node-type-romunidad-web .view-mode-full .field-name-body {
  /*li,
			blockquote,
			table td {
				font-size: 19px; line-height: 155%
			}*/
}

.node-type-romunidad-web .view-mode-full .field-name-body p {
  font-size: 1em;
  color: #5c6368;
  line-height: 165%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body p {
    margin-bottom: 20px;
    font-size: 1.1em;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body p a {
  color: #c37b00;
  font-weight: bold;
}

.node-type-romunidad-web .view-mode-full .field-name-body p a:hover {
  color: #c37b00;
}

.node-type-romunidad-web .view-mode-full .field-name-body p strong {
  color: #c37b00;
  font-weight: bold;
}

.node-type-romunidad-web .view-mode-full .field-name-body blockquote {
  font-family: 700;
  font-weight: normal;
  color: #5c6368;
  background-color: #ddd;
  margin: 0 0 20px;
  padding: 30px 35px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body blockquote {
    margin: 0 1em 20px;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body blockquote p {
  margin-bottom: 0;
}

.node-type-romunidad-web .view-mode-full .field-name-body blockquote p + p {
  margin-top: 10px;
}

.node-type-romunidad-web .view-mode-full .field-name-body blockquote a {
  color: #8e2321;
}

.node-type-romunidad-web .view-mode-full .field-name-body blockquote strong {
  color: #8e2321;
}

.node-type-romunidad-web .view-mode-full .field-name-body ul, .node-type-romunidad-web .view-mode-full .field-name-body ol {
  padding-left: 16px;
  margin: 0 0 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body ul, .node-type-romunidad-web .view-mode-full .field-name-body ol {
    padding-left: 16px;
    margin-bottom: 20px;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body li {
  font-size: 1em;
  line-height: 165%;
  padding-left: 6.66667px;
  margin-bottom: 20px;
  color: #5c6368;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body li {
    padding-left: 20px;
    font-size: 1.1em;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body li strong {
  color: #4d2200;
  font-weight: 700;
}

.node-type-romunidad-web .view-mode-full .field-name-body li a {
  color: #5c6368;
}

.node-type-romunidad-web .view-mode-full .field-name-body td {
  font-size: 1.1em;
  line-height: 160%;
}

.node-type-romunidad-web .view-mode-full .field-name-body p + h1, .node-type-romunidad-web .view-mode-full .field-name-body p + h2, .node-type-romunidad-web .view-mode-full .field-name-body p + h3, .node-type-romunidad-web .view-mode-full .field-name-body ul + h1, .node-type-romunidad-web .view-mode-full .field-name-body ul + h2, .node-type-romunidad-web .view-mode-full .field-name-body ul + h3, .node-type-romunidad-web .view-mode-full .field-name-body ol + h1, .node-type-romunidad-web .view-mode-full .field-name-body ol + h2, .node-type-romunidad-web .view-mode-full .field-name-body ol + h3,
.node-type-romunidad-web .view-mode-full .field-name-body blockquote + h1, .node-type-romunidad-web .view-mode-full .field-name-body blockquote + h2, .node-type-romunidad-web .view-mode-full .field-name-body blockquote + h3,
.node-type-romunidad-web .view-mode-full .field-name-body table + h1, .node-type-romunidad-web .view-mode-full .field-name-body table + h2, .node-type-romunidad-web .view-mode-full .field-name-body table + h3 {
  margin-top: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body p + h1, .node-type-romunidad-web .view-mode-full .field-name-body p + h2, .node-type-romunidad-web .view-mode-full .field-name-body p + h3, .node-type-romunidad-web .view-mode-full .field-name-body ul + h1, .node-type-romunidad-web .view-mode-full .field-name-body ul + h2, .node-type-romunidad-web .view-mode-full .field-name-body ul + h3, .node-type-romunidad-web .view-mode-full .field-name-body ol + h1, .node-type-romunidad-web .view-mode-full .field-name-body ol + h2, .node-type-romunidad-web .view-mode-full .field-name-body ol + h3,
  .node-type-romunidad-web .view-mode-full .field-name-body blockquote + h1, .node-type-romunidad-web .view-mode-full .field-name-body blockquote + h2, .node-type-romunidad-web .view-mode-full .field-name-body blockquote + h3,
  .node-type-romunidad-web .view-mode-full .field-name-body table + h1, .node-type-romunidad-web .view-mode-full .field-name-body table + h2, .node-type-romunidad-web .view-mode-full .field-name-body table + h3 {
    margin-top: 20px;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body img {
  max-width: 100%;
  height: auto;
}

.node-type-romunidad-web .view-mode-full .field-name-body img[style*="float: left;"] {
  margin-right: 20px;
}

.node-type-romunidad-web .view-mode-full .field-name-body img[style*="float: right;"] {
  margin-left: 20px;
}

.node-type-romunidad-web .view-mode-full .field-name-body .field {
  margin-bottom: 20px;
}

.node-type-romunidad-web .view-mode-full .field-name-body ul li {
  position: relative;
  list-style: none;
}

.node-type-romunidad-web .view-mode-full .field-name-body ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background: #4d2200;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 13px;
}

@media only screen and (max-width: 1020px) {
  .node-type-romunidad-web .view-mode-full .field-name-body ul li::before {
    top: 9px;
  }
}

.node-type-romunidad-web .view-mode-full .field-name-body ol li {
  list-style: none;
  position: relative;
}

.node-type-romunidad-web .view-mode-full .field-name-body ol li::before {
  position: absolute;
  left: -15px;
  top: 0;
  color: #4d2200;
  counter-increment: section;
  content: counter(section, decimal) ") ";
}

.node-type-romunidad-web .view-mode-full .field-name-body ol + ul,
.node-type-romunidad-web .view-mode-full .field-name-body ul + ol {
  margin-left: 35px;
}

.node-type-romunidad-web .node {
  height: 100%;
}

.node-type-romunidad-web .group-left {
  background-color: #d9e4fb;
}

.node-type-romunidad-web .group-left .field-name-field-image {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.node-type-romunidad-web .group-left .field-name-field-image .field-items,
.node-type-romunidad-web .group-left .field-name-field-image .field-item {
  width: 100%;
  height: 100%;
}

.node-type-romunidad-web .group-left .field-name-field-image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

.node-type-romunidad-web #block-bean-romunidad-foto-fija-1,
.node-type-romunidad-web .group-left,
.node-type-romunidad-web .group-right {
  height: 50%;
  width: 100%;
  padding: 0 20px;
  min-height: 450px;
  position: relative;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web #block-bean-romunidad-foto-fija-1,
  .node-type-romunidad-web .group-left,
  .node-type-romunidad-web .group-right {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1020px) {
  .node-type-romunidad-web #block-bean-romunidad-foto-fija-1,
  .node-type-romunidad-web .group-left,
  .node-type-romunidad-web .group-right {
    background-size: auto 62% !important;
  }
}

.node-type-romunidad-web #block-bean-romunidad-foto-fija-1 {
  width: 100%;
}

.node-type-romunidad-web .group-right {
  /*padding-top: 110px;
	
	& > * {
		padding: 0 51px;
	}
	*/
}

@media only screen and (max-width: 1020px) {
  .node-type-romunidad-web .group-right {
    height: auto;
    padding: 0;
  }
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .group-right > .group-text-content {
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 40px;
  }
}

.node-type-romunidad-web .group-right .field-name-field-video-embed {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .group-right .field-name-field-video-embed {
    margin-bottom: 32px;
  }
}

.node-type-romunidad-web .group-right .field-name-field-video-embed iframe {
  max-width: 100%;
  display: block;
}

.node-type-romunidad-web .group-right .field-name-field-image {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .group-right .field-name-field-image {
    margin-bottom: 32px;
  }
}

.node-type-romunidad-web .group-right .field-name-field-image img {
  width: 100%;
  height: auto;
  display: block;
}

.node-type-romunidad-web .field-name-title {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.node-type-romunidad-web .field-name-title * {
  margin: 0;
}

.node-type-romunidad-web .group-title-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 20;
}

.node-type-romunidad-web .group-title-content .field-group-div {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.node-type-romunidad-web .group-title-content .field-group-div > * {
  display: table-cell;
  vertical-align: middle;
}

.node-type-romunidad-web main .field-name-title {
  font-size: 1.3em;
  line-height: 110%;
  font-weight: 600;
  color: #4d2200;
}

.node-type-romunidad-web main .field-name-title * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.node-type-romunidad-web .field-name-main-menu {
  padding-bottom: 30px;
  margin-top: 30px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .field-name-main-menu {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}

.node-type-romunidad-web .field-name-main-menu .front-page,
.node-type-romunidad-web .field-name-main-menu .portal-etica {
  display: none;
}

.node-type-romunidad-web .field-name-main-menu .block__title {
  display: none;
}

.node-type-romunidad-web .field-name-main-menu .visitado .menu__item a {
  background-color: #d9e4fb;
}

.node-type-romunidad-web .field-name-main-menu .views-row-inner {
  position: relative;
}

.node-type-romunidad-web .field-name-main-menu .views-row-inner:hover .menu__item + .colorfield-color-swatch {
  opacity: 0;
}

.node-type-romunidad-web .field-name-main-menu .views-row-inner:hover .default-a {
  opacity: 0;
}

.node-type-romunidad-web .field-name-main-menu .menu {
  padding: 0;
  margin: 0;
}

.node-type-romunidad-web .field-name-main-menu .menu__item {
  list-style: none;
  margin-bottom: 24px;
  z-index: 50;
  position: relative;
}

.node-type-romunidad-web .field-name-main-menu .menu__item a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
  color: #3d464d;
  padding: 1.5em 1em;
  background: none;
  padding: 1.5em 0 !important;
  display: block;
  text-align: center;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .field-name-main-menu .menu__item a {
    padding: 0.8em 0.9em;
  }
}

.node-type-romunidad-web .field-name-main-menu .menu__item a:hover {
  background-color: #01549f;
}

.node-type-romunidad-web .field-name-main-menu .menu__item a:hover {
  color: #3d464d;
  background-color: #d9e4fb;
}

.node-type-romunidad-web .field-name-main-menu .colorfield-color-swatch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 30;
  transition: .3s ease-out all;
}

.node-type-romunidad-web .field-name-main-menu .colorfield-color-swatch + .default-color {
  display: none;
}

.node-type-romunidad-web .field-name-main-menu .default-color .default-a,
.node-type-romunidad-web .field-name-main-menu .default-color .default-b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 30;
  transition: .3s ease-out all;
  background: #89a1d2;
}

.node-type-romunidad-web .field-name-main-menu .default-color .default-b {
  z-index: 20;
  background: #01549f;
}

.node-type-romunidad-web .group-slide-content {
  max-width: 730px;
  margin: 0 auto;
  padding: 50px 20px;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .group-slide-content {
    padding-top: 110px;
    padding: 110px 40px 50px;
    min-height: 100%;
  }
}

.node-type-romunidad-web .field-name-field-links {
  margin-top: 60px;
}

.node-type-romunidad-web .field-name-field-links .field-item {
  margin-bottom: 22px;
}

.node-type-romunidad-web .field-name-field-links a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 0.6em;
  font-size: 1em;
  text-decoration: none;
  background-color: #4d2200;
  color: #d9e4fb;
  color: #3d464d;
  padding: 1.5em 1em;
  background-color: #8b6a50;
  display: block;
  text-align: center;
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .field-name-field-links a {
    padding: 0.8em 0.9em;
  }
}

.node-type-romunidad-web .field-name-field-links a:hover {
  background-color: #01549f;
}

.node-type-romunidad-web .field-name-field-links a:hover {
  background-color: #6b513d;
  color: white;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .group-conditional-blocks {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.node-type-romunidad-web .group-footer {
  border-top: 1px solid #ccc;
  overflow: hidden;
  padding: 28px 35px 35px;
}

.node-type-romunidad-web .field-name-footer-copyright {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-romunidad-web .field-name-footer-copyright {
    float: left;
    text-align: right;
  }
}

.node-type-romunidad-web .field-name-footer-copyright p {
  font-size: .9em;
  color: #83858c;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-romunidad-web .field-name-footer-copyright p {
    float: left;
  }
}

.node-type-romunidad-web .field-name-footer-author {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .node-type-romunidad-web .field-name-footer-author {
    float: right;
    text-align: right;
  }
}

.node-type-romunidad-web .field-name-footer-author .block-inner {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

.node-type-romunidad-web .field-name-footer-author .block__title {
  font-size: .7em;
  float: left;
  margin: 5px 5px 0 0;
  color: #83858c;
}

.node-type-romunidad-web .field-name-footer-author .entity {
  float: left;
}

.node-type-romunidad-web .field-name-footer-author .field-name-field-image {
  margin: 0 !important;
}

.node-type-romunidad-web .field-name-footer-author img {
  max-width: 65px;
  height: auto;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .field-name-title {
    margin-bottom: 37px;
  }
}

.node-type-romunidad-web .easy-breadcrumb_segment-title {
  display: none;
}

.node-type-romunidad-web .easy-breadcrumb_segment-1 + .easy-breadcrumb_segment-separator {
  display: none;
}

.node-type-romunidad-web .easy-breadcrumb_segment-2 {
  font-size: 2em;
  line-height: 110%;
  font-weight: 700;
  display: block;
  color: #4d2200;
  font-weight: 600;
}

.node-type-romunidad-web .easy-breadcrumb_segment-2 * {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media only screen and (min-width: 1020px) {
  .node-type-romunidad-web .easy-breadcrumb_segment-2 {
    font-size: 2.5em;
    line-height: 110%;
  }
}

.node-type-romunidad-web .easy-breadcrumb_segment-2 + .easy-breadcrumb_segment-separator {
  display: none;
}

.node-type-romunidad-web .group-left .field-name-field-image > .block {
  height: 100%;
}

@media only screen and (max-width: 1020px) {
  .node-type-romunidad-web .group-left .field-name-field-image > .block {
    background-size: auto 82% !important;
  }
}

.page-user {
  background-color: #d9e4fb;
  overflow: visible;
}

.page-user .header {
  position: relative;
}

.page-user .header + .layout-3col {
  padding: 100px 0;
  margin: 0 auto;
  max-width: 600px;
}
