form {
	// Use this with js/scripts/ function inputFileStyles()
	.webform-component-file {
		//padding-top: 22px; // a) por el description con margen top negativo

		.description {
			display: none;
		}

		.form-managed-file {
			position: relative;
			z-index: 10;
			
			/*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file
				
				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
		}

		label {
			@include custom-button-2;
			font-weight: inherit;
			font-size: inherit;
			cursor: pointer;
			margin: 0;
			// agregando otros estilos
			background-color: #fff;
			background-size: 15px auto;
			color: #8b6a50;
			border: 2px solid #8b6a50;
			transition: .3s ease-out all;
			width: 100%;
			padding-top: .63rem;
			padding-bottom: .63rem;
			padding: 11px 10px 9px !important;

			&:hover {
				background-color: darken-hover($pastel-marron);
				border-color: darken-hover($pastel-marron);
				color: white;
			}
		}

		.form-file {
			//width: 0.1px;
			height: 50.1px; //0.1px;
			opacity: 0;
			//overflow: hidden;
			position: absolute;
			z-index: 2;//-1;
			cursor: pointer;

			&:focus + label {
				//color: white;
				//background-color: #8b6a50;
				//outline: 1px dotted #000;
				//outline: -webkit-focus-ring-color auto 5px;
			}
		}

		.form-submit {
			display: none;
		}
	}
}
