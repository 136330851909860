.front {
	.blockgroup {
		height: 100%;
	}
}





