.main-menu {
	@include centered-absolute;
	@include contextual-links(100px);
	z-index: 100;

	.block-inner {
		margin: auto 20px;
		height: 100%;
		margin: auto 10%;

		@media only screen and (min-width: $desktop) {
			margin: auto 40%;
		}

		.content {
			@include vertical-align-text;
		}
	}
	
	.content-inner {
		text-align: center;
		position: relative; // acerca el enlace contextual
	}

	.block__title {
		@include font-heading;
		text-align: center;
		color: $base-marron;
		//padding-left: 54px;
		margin-bottom: 10px;

		@media only screen and (max-width: $mobile) {
			display: none;
		}
	}

	.menu {
		@include inline-block;
		text-align: left;
		padding: 0;
		margin: 0;

		.menu__item {
			list-style: none;
			margin-bottom: 12px;

			&.last {
				margin-bottom: 0;
			}
		}

		.menu__link {
			color: $base-marron;
			text-decoration: none;

			&:hover,
			&.is-active-trail {
				color: $base-blue;
			}

			&::before {
				content: "> ";
			}

			// El view menu tiene el <a> como hijo de la clase menu__link
			a {
				color: $base-marron;
				text-decoration: none;

				&.active {
					color: $base-blue;
				}
			}
		}
	}
}
