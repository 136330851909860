/*html.front, body.front, 
body.front .layout-center,
body.front .layout-swap,
body.front .layout-3col__full {
	height: 100%;
}*/

body {
	counter-reset: section;

	@media only screen and (min-width: $desktop) {
		overflow-y: hidden;
	}

	&.hamburger-open {
		overflow-y: hidden;

		@media only screen and (max-width: $mobile) {
			.header__logo {
				display: none;
			}
		}
	}
}

html, body, 
.layout-center,
.layout-swap,
.layout-3col__full {
	@media only screen and (min-width: $tablet) {
		height: 100%;
	}
}

.group-left,
.group-right {
	@media only screen and (min-width: $tablet) {
		height: 100%;
	}
}

html.front, body.front, 
.front .layout-center,
.front .layout-swap,
.front .layout-3col__full {
	height: 100%;
}



body {		
	@include montserrat;
	font-size: 14px;

	@media only screen and (min-width: $desktop) {
		font-size: 16px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 10px;
	line-height: 125%;

	@media only screen and (min-width: $tablet) {
		margin-bottom: 15px;
	}
}

p {
	margin: 0 0 15px;
	line-height: 135%;
}

a {
	transition: .3s ease-out all;
}

dl,
menu,
ol,
ul {
	margin: 0 0 15px;
}

// Full width
.layout-center {
	padding: 0;
	max-width: inherit;
}

.layout-swap,
.layout-3col__full {
	margin: 0;
	padding: 0;
}
// END: Full width

.layout-swap__top.layout-3col__full {
	display: none;
}

.region-blockgroup-header-links {
	@include clearfix;
	position: absolute;
	top: 20px;
	right: 25px;
}

#block-bean-logo-entel,
.hamburger-block {
	float: right;
	margin-left: 15px;

	@media only screen and (max-width: $tablet) {
		width: 40px;
		height: 40px;
		margin-left: 5px;
		position: relative;
		top: 0; // 0 para el del main menu
	}
}

.hamburger-button,
.close-button-html {
	@include hamburger-button;
	background-color: $base-celeste-dark; //$base-blue;
	display: block !important;
	width: 60px;
	height: 60px;
	border: 0;
	position: relative;

	@media only screen and (max-width: $tablet) {
		width: 40px;
		height: 40px; //39px;		
	}

	span {
		background-color: white;
		width: 34px; //33px;
		height: 6px;
		border-radius: 0;
	}
}

div .close-button-html {
	width: 49px;
	height: 51px;
	
	span {
		left: 8px;
		height: 6px !important;
		width: 34px !important;
	}

	&.open span:nth-child(1),
	&.open span:nth-child(3) {
		top: 23px !important;
	}
}

.scroll-fieldgroup {
	//overflow:auto;
	//height: 800px;
}

// ya no se está usando
// /portal-de-etica-rom/
.region-blockgroup-section-2,
.region-blockgroup-blockgroup-inner {
	height: 100%;
}

.region-blockgroup-blockgroup-inner {
	@include default-horizontal-vertical-block;
	
	@media only screen and (max-width: $desktop) {
		padding-left: 0;
		padding-right: 0;
	}
	
	
	/*
	height: 50%;
	min-height: 450px;
	position: relative;
	text-align: center;

	@media only screen and (min-width: $desktop) {
		float: left;
		width: 50%;
		height: 100%;
	}*/

	.block-portada {
		width: 100%;
	}

	// Solo en portal-de-ética-rom
	.field-name-easy-breadcrumb-field {
		@include vertical-align-text;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 75px;
		margin: auto;
		text-align: center;
		z-index: 50;
		padding-left: 20px;
		padding-right: 20px;

		@media only screen and (min-width: $desktop) {
			width: 500px;
			text-align: left;
		}

		> * {
			@media only screen and (max-width: $desktop) {
				@include inline-block;
				text-align: left;
			}
		}

		.easy-breadcrumb_segment-front {
			& + .easy-breadcrumb_segment-separator {
				display: none;
			}
		}
	}
}

.close-button-html.open {
	display: block;
	z-index: 10;
	position: absolute;
	top: -12px;
	right: 1px;
	background-color: $pastel-marron;

	span {
		//left: 9px;

		&:nth-child(1),
		&:nth-child(3) {
		//	top: 23px;
		}
	}
	
	&:hover {
		background-color: darken-hover($pastel-marron);
	}
}

.region-footer {
	border-top: 1px solid #ccc;
	overflow: hidden;
	padding: 28px 35px 35px;
	position: relative;
	bottom: 0;
	width: 100%;

	div.block {
		.block-inner {
			height: auto;
			padding: 0;
			overflow: auto;
		}
	}
}

.messages {
	padding-top: 1em;
	padding-bottom: 1em;

	p {
		margin: 0;
	}
}

.tabs {
	padding: 0 30px;
	margin-bottom: 20px;
}

textarea,
input[type="text"],
input[type="submit"],
input[type="password"],
input[type="email"],
select {
	appearance: none;
	border-radius: 0;
}

.necessary {
	box-shadow: 0.5px 0.5px 2px red, -0.5px -0.5px 2px red;
}

.layout-3col + .footer {
	display: none;
}

