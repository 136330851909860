.block-portada {
	@include contextual-links(70px);
	@include default-horizontal-vertical-block;
	text-align: center;

	.block__title {
		@include font-heading;
		color: $base-marron;
	}

	.field-name-field-body {
		max-width: 520px;
		margin: 0 auto 17px;

		p {
			margin: 0;
		}

		@media only screen and (min-width: $desktop) {
			margin-bottom: 23px;
		}
	}

	.field-name-field-link {
		margin-bottom: 10px;

		@media only screen and (min-width: $desktop) {
			margin-bottom: 20px; //25px;
		}
	}

	.field-name-field-link,
	.field-name-field-links {
		a {
			width: 240px;
		}
	}

	.field-name-field-links {
		width: 100%;
		position: absolute;
		top: auto;
		left: 0;

		.field-item {
			margin-bottom: 10px;
		}

		a {
			text-align: left;
		}

		@media only screen and (min-width: $desktop) {
			top: 105%;
			top: calc(100% + 25px);

			.field-item {
				margin-bottom: 20px;
			}
			
			a {
				text-align: center;
			}
		}
	}

	&.section-portal-etica {
		background-color: $base-celeste;
	}

	&.primary {
		background-color: $base-celeste;

		.block__title,
		.field-name-field-body p {
			color: $base-marron;
		}

		.field-name-field-link {
			a {
				@include custom-button-1;
				
			}
		}

		.field-name-field-links {
			a {
				@include custom-button-5;
				background-position: 17px 50%;
				background-repeat: no-repeat;
				padding-left: 2.7rem;
			}

			.field-item:nth-of-type(1) {
				a {
					background-image: url(../img/icon-person.png);
				}
			}

			.field-item:nth-of-type(2) {
				a {
					background-image: url(../img/icon-arrow.png);
				}
			}
		}
	}

	&.secondary {
		background-color: $base-marron;

		.block__title,
		.field-name-field-body {
			color: $base-celeste;
		}

		.field-name-field-link {
			a {
				@include custom-button-2;
			}
		}

		// BUG: cuando pongo color blanco a las fuentes, se visualizan más bold
		.block__title {
			font-weight: 500;
		}
	}

	.block-inner {
		@include vertical-align-text;
	}

	.content-inner {
		@media only screen and (min-width: $desktop) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.group-text-content {
		position: relative;
		z-index: 20;
	}

	.field-name-field-image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		z-index: 15;

		@media only screen and (max-width: $desktop) {
			padding: 20px;
		}
	}

	img {
		max-width: 100%;
		width: auto;
		height: auto;
		max-height: 450px;
		display: block;
		margin: 0 auto;

		@media only screen and (min-width: $desktop) {
			@include centered-absolute;
			max-width: 82%; //90%;
			max-height: inherit;
			//margin-top: 13%;
		}
	}
}
