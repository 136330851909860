.jspPane {
	width: 100% !important;
}

.jspDrag {
	background: #bbb;
}

.jspTrack {
	background: #eee;
}

// select
.fc-select-list-wrapper {
	font-size: .9rem;
}

span.selected-arrow {
	background-color: $pastel-marron;
	width: 46px;
	height: 46px;

	&:hover {
		background-color: darken-hover($pastel-marron);
	}

	&::before {
		@include triangle-bottom(white);
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}

.fc-select-list,
.fc-select-list-selected {
	border: 2px solid $pastel-marron;
}

.fc-select-list {
	border-top: 0;
}

h4.fc-select-list-selected {
	height: 48px;
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
}

ul.fc-select-list {
	background: white;

	li {
		background-color: $pastel-marron;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

ul.fc-select-list li:hover,
ul.fc-select-list li.opt-selected {
	background-color: darken-hover($pastel-marron);
	color: white;
}

.selected-text {
	color: $pastel-marron;
	margin-top: 16px;
}


.carousel-indicators {
	margin: 0;
}
