.block-layout {
	&.block-cards {
		> .block-inner > .content > .content-inner {
			> div.contextual-links-wrapper {
				top: 120px;
			}
		}

		.view {
			width: 100%;
			overflow: hidden;
		}

		.view-content {
			@include block-card-parent;
			padding-bottom: 60px;
		}

		.views-row {
			// @include block-card($title, $summary, $image, $link);
			@include block-card('.field-name-title', '.field-name-body', '.field-name-field-image, .field-type-video-embed-field', '.field-name-node-link');
		} 
	}
}
