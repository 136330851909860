.form-submit {
	@include custom-button-1;
	border: 0;
	color: white;
	background-color: $pastel-marron;
	display: block;
	text-align: center;
	font-size: inherit;
	width: 100%;
	transition: .3s ease-out all;
	padding: 0.8em 0.9em;

	&:hover {
		background-color: darken-hover($pastel-marron);
		color: white;
	}
}
