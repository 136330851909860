	// /*==============================
// =          $artifacts          =
// --------------------------------
// *
// *
// * Global mixins, functions, classes and objects accesible from custom files.
// *
// *
// ==============================*\


// /**
// *
// * scope: WHERE DOES IT OPERATE?
// * type: SILENT CLASS, OBJECT, MIXIN?
// * desc: DESCRIPTION
// *
// **\
//@mixin YOUR-ARTIFACT {
//}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: VERTICALLY ALIGNS TWO ELEMENTS TO TOP, INSTEAD OF USING FLOATS
// *
// **\
@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}

// /**
// *
// * scope: GLOBAL
// * type: FUNCTION
// * desc: DEFAULT DARKEN PROCESS FOR HOVERS
// *
// **\
@function darken-hover($color) {
	@return darken($color, 10%);
}


// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: VERTICAL ALIGNS TO TOP
// *
// **\
@mixin vertical-align-text {
	display: table;
	height: 100%;
	width: 100%;
	
	& > * {
		display: table-cell;
		vertical-align: middle;
	}
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: just a full width
// *
// **\
@mixin full-width {
	width: 100%;
	float: left;
	clear: left;
}

// /**
// *
// * scope: WHERE DOES IT OPERATE?
// * type: SILENT CLASS, OBJECT, MIXIN?
// * desc: DESCRIPTION
// *
// **\
@function darken-hover($color) {
	@return darken($color, 10%);
}

@mixin background-hover($color) {
	background-color: $color;
	transition: .3s ease-out all;
	
	&:hover {
		background-color: darken-hover($color);
	}
}

// /**
// *
// * scope: global
// * type: MIXIN
// * desc: Wrapper
// *
// **\
@mixin wrapper-content {
	margin: 0 auto;
	max-width: 1240px;
	padding-left: 20px;
	padding-right: 20px;
	
	@media only screen and (min-width: $mobile) and (max-width: 777px) {
		& {
			padding-left: 12px;
			padding-right: 13px;
		}
	}
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: triangle css, http://apps.eky.hk/css-triangle-generator/
// *
// **\
@mixin triangle-right($color) {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5.5px 0 5.5px 10px;
	border-color: transparent transparent transparent $color;
	//border-width: 0 12.5px 12px 12.5px;
	//border-color: transparent transparent $color;
}


// /**
// *
// * scope: global
// * type: MIXIN
// * desc: Hamburger mobile button
// * requires: <div class="hamburger-button"><span></span><span></span><span></span></div>
// *
// **\
@mixin hamburger-button {
	$local-color: white;
	width: 40px;
	height: 40px;
	position: absolute;
	//top: 15px;
	//right: 10px;
	overflow: hidden;
	margin: 0 0 0 5px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	border: 1px solid $local-color;
	z-index: 10;

	@media only screen and (min-width: $mobile) {
		display: none;
	}
	
	span {
		display: block;
		position: absolute;
		width: 25px;
		height: 3.5px;
		background: $local-color;
		border-radius: 9px;
		opacity: 1;
		left: 13px;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
	  		top: 16px;
		}

		&:nth-child(2) {
	  		top: 27px;
		}

		&:nth-child(3) {
	  		top: 38px;
		}

		@media only screen and (min-width: $mobile) and (max-width: $desktop) {
			/*width: 30px;

			&:nth-child(1) {
		  		top: 14px;
			}

			&:nth-child(2) {
		  		top: 24px;
			}

			&:nth-child(3) {
		  		top: 34px;
			}*/
		}

		@media only screen and (max-width: $tablet) {
			width: 26px !important;
			left: 7px !important;
			height: 4px !important;

			&:nth-child(1) {
		  		top: 8px;
			}

			&:nth-child(2) {
		  		top: 18px;
			}

			&:nth-child(3) {
		  		top: 28px;
			}
		}
	}

	&.open {
		span {
			&:nth-child(1) {
		  		top: 28px;
				transform: rotate(135deg);

				@media only screen and (max-width: $tablet) {
					top: 19px;
				}
			}

			&:nth-child(2) {
				opacity: 0;
				left: -60px;
			}

			&:nth-child(3) {
				top: 28px;
				transform: rotate(-135deg);

				@media only screen and (max-width: $tablet) {
					top: 19px;
				}
			}
		}
	}
}

@mixin montserrat {
	font-family: 'Montserrat';
	font-weight: 300;
}

@mixin montserrat-bold {
	font-family: 'Montserrat';
	font-weight: 700;
}

@mixin custom-button-defaults {
	@include inline-block;
	padding: 0.5em 0.6em;
	font-size: 1em;
	text-decoration: none;

	@media only screen and (min-width: $desktop) {
		padding: 0.8em 0.9em;
	}
}

@mixin custom-button-1 {
	@include custom-button-defaults;
	background-color: $base-marron;
	color: $base-celeste;

	&:hover {
		background-color: $hover-blue;
	}
}

@mixin custom-button-2 {
	@include custom-button-defaults;
	background-color: $base-celeste;
	color: $base-marron;

	&:hover {
		background-color: $hover-blue;
		color: $base-celeste;
	}
}

@mixin custom-button-3 {
	@include custom-button-defaults;
	border: 2px solid $base-celeste-dark;
	color: $base-celeste-dark;

	&:hover {
		border-color: darken-hover($base-celeste-dark);
		color: darken-hover($base-celeste-dark);	
	}
}

@mixin custom-button-4 {
	@include custom-button-1;
	color: $dark-2;
	padding: 1.5em 1em;
}


@mixin custom-button-5 {
	@include custom-button-defaults;
	background-color: $base-celeste-dark;
	color: $base-celeste;

	&:hover {
		background-color: $hover-blue;
	}
}

@mixin centered-absolute {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

@mixin area-default {
	padding: 50px 60px;
	
	@media only screen and (min-width: $tablet) {
		padding: 100px 60px;
	}
}

@mixin area-default-scroll {
	/*padding-top: 110px;
	
	& > * {
		padding: 0 51px;
	}
	*/
}

// BLOCK CARD ELEMENTS
@mixin block-card-parent {
	//overflow: hidden;
	//margin: 0 -20px;
	text-align: center;
}

@mixin block-card($title, $summary, $image, $link) {
	@include inline-block;
	width: 280px;
	//height: 400px;
	//float: left;
	//padding: 0 20px 0;
	margin-bottom: 40px; // margin y no padding por un bug en ie
	box-sizing: content-box;

	@media only screen and (min-width: $mobile) {
		margin-left: 20px;
		margin-right: 20px;
	}

	& > * {
		border: 1px solid #ccc;
		color: $dark;
	}

	.group-text-content {
		padding-bottom: 20px;
	}

	#{$title} {
		@include font-heading-teaser;
		text-align: center;
		color: $dark;
		padding: 0 20px;
		margin-bottom: 13px;

		* {
			margin: 0;
			font-size: inherit;
			font-weight: inherit;
		}
	}

	#{$summary} {
		@include font-body-teaser;
		color: $dark-2;
		padding: 0 20px;
		text-align: left;
		line-height: 140%;

		* {
			font-size: inherit;
			color: inherit;
			font-weight: inherit;
			margin: 0;
			line-height: inherit;
		}
	}

	#{$image} {
		margin-bottom: 22px;
		position: relative;
		z-index: 10;
		overflow: hidden;

		a {
			display: block;
		}

		img {
			display: block;
			margin: auto;
			height: 200px;
			width: auto;
		}
	}

	.field-name-field-video-embed {
		img {
			width: 100%;
		}
	}

	#{$link} {
		padding: 0 20px 20px;

		a {
			@include custom-button-3;
			color: $dark;
			border: 1px solid #ccc;
			display: block;
			text-align: center;
		}
	}

	.group-image-wrapper {
		position: relative;
	}

	.field-name-field-color {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;

		.field-items,
		.field-item {
			height: 100%;
			width: 100%;
		}

		.colorfield-color-swatch {
			width: 100% !important;
			height: 100% !important;
		}
	}

	.field-name-field-cargo {
		font-size: .9em;
		line-height: 100%;
		margin-bottom: 3px;	
	}

	.field-name-field-sucursal {
		margin-top: -13px;
		margin-bottom: 3px;
		font-size: .9em;
		line-height: 100%;
	}

	.field-name-field-fecha {
		margin-bottom: 15px;
		font-size: .9em;
		line-height: 100%;
	}
}

@mixin copyright {
	margin: 0 auto;
	text-align: center;

	@media only screen and (min-width: 1280px) {
		float: left;
		text-align: right;
	}

	p {
		font-size: .9em;
		color: #83858c;
		margin: 0;
		text-align: center;

		@media only screen and (min-width: 1280px) {
			float: left;
		}
	}
}

@mixin author {
	margin: 0 auto;
	text-align: center;

	@media only screen and (min-width: 1280px) {
		float: right;
		text-align: right;
	}

	.block-inner {
		@include inline-block;
		width: auto;
	}

	.block__title {
		font-size: .7em;
		float: left;
		margin: 5px 5px 0 0;
		color: #83858c;
	}

	.entity {
		float: left;
	}

	.field-name-field-image {
		margin: 0 !important;
	}

	img {
		max-width: 65px;
		height: auto;
	}
}

@mixin font-heading {
	font-size: 2em;
	line-height: 110%;
	font-weight: 700;

	* {
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		text-decoration: none;
	}

	@media only screen and (min-width: $desktop) {
		font-size: 2.5em;
		line-height: 110%;
	}
}

@mixin font-heading-teaser {
	font-size: 1.2em;
	line-height: 110%;

	* {
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		text-decoration: none;
	}
}

@mixin font-heading-teaser-2 {
	font-size: 1.3em;
	line-height: 110%;
	font-weight: 600;

	* {
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		text-decoration: none;
	}
}

@mixin font-body-teaser {
	font-size: 0.85em;
}

@mixin contextual-links($top) {
	div.contextual-links-wrapper {
		top: $top;
	}
	
	/*div.contextual-links-wrapper ul.contextual-links {
		
	}*/
}

@mixin default-horizontal-vertical-block {
	height: 50%;
	width: 100%;
	padding: 0 20px;
	min-height: 450px;
	position: relative;

	@media only screen and (min-width: $desktop) {
		float: left;
		width: 50%;
		height: 100%;
		padding: 0;
	}

	@media only screen and (max-width: $desktop) {
		background-size: auto 62% !important;
	}
}


@mixin body-view-mode-full {
	$paragraph-margin-bottom: 20px;
	$paragraph-margin-bottom-mobile: 20px;
	$paragraph-size: 1.1em;
	$paragraph-size-mobile: 1em; //0.9em;
	$paragraph-color: $dark;
	$paragraph-anchor-color: #c37b00;
	$paragraph-anchor-weight: bold;
	$paragraph-line-height: 165%; //120%;
	$blockquote-background: #ddd;
	$blockquote-font-family: 700;
	$blockquote-font-weight: normal;
	$blockquote-color: $dark;
	$blockquote-anchor-color: #8e2321;
	$blockquote-anchor-color-hover: #8e2321;
	$blockquote-strong-color: #8e2321;
	$blockquote-padding: 30px 35px;
	$ul-margin-bottom: $paragraph-margin-bottom;
	$ul-indent: 16px;
	$list-font-size: $paragraph-size;
	$list-font-size-mobile: $paragraph-size-mobile;
	$list-color: $paragraph-color;
	$list-anchor-color: $paragraph-color;
	$list-strong-color: $base-marron;
	$list-line-height: $paragraph-line-height;
	$list-margin-bottom: 0;
	$list-indent: 20px; // padding-left
	$list-bullet-default: true; // Use default bullet or not
	$list-bullet-image: url(../img/bullet-icon.png);
	$list-bullet-position: 0 10px;
	$table-td-font-size: $paragraph-size;
	$table-td-line-height: 160%;

	/* NODE TEXT STYLES v1 */
	.view-mode-full {
		.field-name-body-summary {
			p {
				font-size: $paragraph-size; line-height: 155%;
			}
		}

		.field-name-body {
			//p, div {
			p {
				font-size: $paragraph-size-mobile;
				color: $paragraph-color;
				line-height: $paragraph-line-height;
				margin-bottom: $paragraph-margin-bottom-mobile;
				
				@media only screen and (min-width: $desktop) {
					margin-bottom: $paragraph-margin-bottom;
					font-size: $paragraph-size;
				}

				a {
					color: $paragraph-anchor-color;
					font-weight: $paragraph-anchor-weight;

					&:hover {
						color: $paragraph-anchor-color;
					}
				}

				strong {
					color: $paragraph-anchor-color;
					font-weight: $paragraph-anchor-weight;
				}
			}

			blockquote {
				font-family: $blockquote-font-family;
				font-weight: $blockquote-font-weight;
				color: $blockquote-color;
				background-color: $blockquote-background;
				margin: 0 0 $paragraph-margin-bottom;
				padding: $blockquote-padding;

				@media only screen and (min-width: $desktop) {
					margin: 0 1em $paragraph-margin-bottom;
				}

				p {
					margin-bottom: 0;
				}

				p + p {
					margin-top: $paragraph-margin-bottom/2;
				}

				a {
					color: $blockquote-anchor-color;
				}

				strong {
					color: $blockquote-strong-color;
				}
			}

			ul, ol {
				padding-left: $ul-indent;
				margin: 0 0 $ul-margin-bottom;

				@media only screen and (min-width: $desktop) {
					padding-left: $ul-indent;
					margin-bottom: $ul-margin-bottom;
				}
			}

			ul li {
				@if ($list-bullet-default == false) {
					list-style: none;
					background-image: $list-bullet-image;
					background-position: $list-bullet-position;
					background-repeat: no-repeat;
				} 
			}

			li {
				font-size: $list-font-size-mobile;
				line-height: $list-line-height;	
				padding-left: $list-indent/3;
				margin-bottom: $paragraph-margin-bottom;
				color: $list-color;
				
				@media only screen and (min-width: $desktop) {
					padding-left: $list-indent;
					font-size: $list-font-size;
				}

				strong {
					color: $list-strong-color;
					font-weight: 700;
				}

				a {
					color: $list-anchor-color;
				}
			}

			td {
				font-size: $table-td-font-size;
				line-height: $table-td-line-height;
			}

			/*li,
			blockquote,
			table td {
				font-size: 19px; line-height: 155%
			}*/

			h1 {
			//	font-size: 44px;
			}

			h2 {
			//	font-size: 44px;
			}

			h3 {
			//	font-size: 44px;
			}

			h4 {
			//	font-size: 44px;
			}

			h5 {
			//	font-size: 44px;
			}

			h6 {
			//	font-size: 44px;
			}
	
			p + h1, p + h2, p + h3, ul + h1, ul + h2, ul + h3, ol + h1, ol + h2, ol + h3,
			blockquote + h1, blockquote + h2, blockquote + h3, 
			table + h1, table + h2, table + h3 {
				margin-top: $paragraph-margin-bottom-mobile;

				@media only screen and (min-width: $desktop) {
					margin-top: $paragraph-margin-bottom;
				}
			} 

			img {
				max-width: 100%;
				height: auto;

				&[style*="float: left;"] {
					margin-right: 20px;
				}

				&[style*="float: right;"] {
					margin-left: 20px;
				}
			}

			.field {
				margin-bottom: 20px;
			}
		
			// custom
			ul {
				li {
					position: relative;
					list-style: none;
					
					&::before {
						content: "";
						width: 5px;
						height: 5px;
						background: #4d2200;
						border-radius: 50%;
						position: absolute;
						left: -15px;
						top: 13px;

						@media only screen and (max-width: $desktop) {
							top: 9px;
						}
					}
				}
			}

			ol {
				li {
					list-style: none;
					position: relative;

					&::before {
						position: absolute;
						left: -15px;
						top: 0;
						color: #4d2200;
						counter-increment: section; 
						content: counter(section, decimal) ") "; 
					}
				}
			}

			ol + ul,
			ul + ol {
				margin-left: 35px;
			}
		}
	}
}

@mixin triangle-bottom($color) {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 14px 13px 0 13px;
	border-color: $color transparent transparent
}

@mixin form-text {
	border: 2px solid $pastel-marron;
	font-size: inherit; //.9em;
	padding: 10px;
	width: 100%;
	height: 48px;
	color: $pastel-marron;

	&::placeholder {
		opacity: 1;
		color: $pastel-marron;
	}
}

@mixin interna-default {
	@include body-view-mode-full;
	$local-side-padding: 60px;

	.node {
		height: 100%;
	}

	.group-left {
		background-color: $base-celeste;

		.field-name-field-image {
			z-index: 10;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			.field-items,
			.field-item {
				width: 100%;
				height: 100%;
			}

			img {
				@include centered-absolute;
				max-width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	
	#block-bean-romunidad-foto-fija-1,
	.group-left,
	.group-right {
		@include default-horizontal-vertical-block;
	}
	
	#block-bean-romunidad-foto-fija-1 {
		width: 100%;
	}

	.group-right {
		@include area-default-scroll;

		@media only screen and (min-width: $tablet) {
			//padding-left: 0; // tengo que resetear por el overflow del scroll, que no permite que el menú bottom sea full width
		}

		@media only screen and (max-width: $desktop) {
			height: auto;
			padding: 0;
		}

		> .group-text-content {
			.group-slide-content {
				@media only screen and (max-width: $desktop) {
				//	padding-left: 20px;
				//	padding-right: 20px;
				}
			}

			@media only screen and (min-width: $desktop) {
				height: 100%;
				overflow-x: hidden;
				padding-bottom: 40px;
			}
		}

		.field-name-field-video-embed {
			margin-bottom: 20px;

			@media only screen and (min-width: $desktop) {
				margin-bottom: 32px;
			}

			iframe {
				max-width: 100%;
				display: block;
			}
		}

		.field-name-field-image {
			margin-bottom: 20px;

			@media only screen and (min-width: $desktop) {
				margin-bottom: 32px;
			}

			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}

	.field-name-title {
		margin-bottom: 20px;
		font-size: 1.5em;

		@media only screen and (min-width: $desktop) {
			// no se necesita en: http://www.rom.ricardo-castaneda.com/portal-de-etica-rom/c%C3%B3digo-de-%C3%A9tica-rom
			// se necesita en romunidad
			//margin-bottom: 35px;
		}

		* {
			margin: 0;
		}
	}

	.group-title-content {
		@include centered-absolute;
		z-index: 20;

		.field-group-div {
			@include vertical-align-text;
			text-align: center;
		}
	}

	main {
		.field-name-title {
			@include font-heading-teaser-2;
			color: $base-marron;
		}
	}

	.field-name-main-menu {
		padding-bottom: 30px;
		//margin-left: -$local-side-padding;;
		//margin-right: -$local-side-padding;;
		margin-top: 30px;

		@media only screen and (min-width: $desktop) {
			margin-top: 60px; //125px;
			padding-bottom: 60px;
		}

		.front-page,
		.portal-etica {
			display: none;
		}

		.block__title {
			display: none;
		}

		.visitado .menu__item a {
			background-color: $base-celeste;
		}

		.views-row-inner {
			position: relative;

			&:hover {
				.menu__item + .colorfield-color-swatch {
					opacity: 0;
				}

				.default-a {
					opacity: 0;
				}
			}
		}

		.menu {
			padding: 0;
			margin: 0;
		}

		.menu__item {
			list-style: none;
			margin-bottom: 24px;
			z-index: 50;
			position: relative;

			a {
				@include custom-button-4;
				background: none;
				padding: 1.5em 0 !important;
				display: block;
				text-align: center;

				&:hover {
					color: $dark-2;
					background-color: $base-celeste;
				}
			}
		}

		// Default color
		.colorfield-color-swatch {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
			z-index: 30;
			transition: .3s ease-out all;

			// Hover
			&:hover {
				
			}

			& + .default-color {
				display: none;
			}
		}

		// Blue default color (empty color field)
		.default-color {
			.default-a,
			.default-b {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
				z-index: 30;
				transition: .3s ease-out all;
				background: $base-celeste-dark;
			}

			.default-b {
				z-index: 20;
				background: $base-blue;
			}
		}
	}

	.group-slide-content {
		//padding-top: 50px;
		max-width: 730px; //650px; //555px;
		margin: 0 auto;
		padding: 50px 20px;

		@media only screen and (min-width: $desktop) {
			padding-top: 110px;
			padding: 110px 40px 50px;
			min-height: 100%;
		}
		//padding-left: $local-side-padding;
		//padding-right: $local-side-padding;
	}

	.field-name-field-links {
		margin-top: 60px;

		.field-item {
			margin-bottom: 22px;
		}

		a {
			@include custom-button-4;
			background-color: $pastel-marron;
			display: block;
			text-align: center;
			color: white;
			cursor: pointer;

			&:hover {
				background-color: darken-hover($pastel-marron);
				color: white;
			}
		}
	}

	.group-conditional-blocks {
		@media only screen and (min-width: $desktop) {
			margin-left: -60px;
			margin-right: -60px;	
		}
	}

	.group-footer {
		border-top: 1px solid #ccc;
		overflow: hidden;
		padding: 28px 35px 35px;
	}

	.field-name-footer-copyright {
		@include copyright;
	}

	.field-name-footer-author {
		@include author;
	}
}

@mixin breadcrumb-title {
	@include font-heading;
	display: block;
	color: $base-marron;
	font-weight: 600;
}


